import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import SEO from '../../components/SEO';
import Header from '../../components/Header';
import ContentRow from '../../components/ContentRow';
import { commonHeaderColor } from '../../util';


const Page = ({ data }) => {
  const { t, ...r } = useTranslation();

  console.log(r);

  return (
    <>
      <SEO
        title="Buy Neosurf online with crypto"
        description="For users who are familiar with Neosurf, Bitcoin offers a similar level of privacy and control over your spending."
        alternateLanguagePages={[
          {
            path: 'buy-neosurf/buy-neosurf-online',
            language: 'en'
          },
          {
            path: 'acheter-neosurf/acheter-neosurf-en-ligne',
            language: 'fr'
          },
          {
            path: 'buy-neosurf/kaufen-neosurf-online',
            language: 'de'
          }
        ]}
      />
      <Header backgroundColor={commonHeaderColor} />
      <ContentRow backgroundColor={commonHeaderColor} padV>
        <div className="grid c-2">
          <div>
            <a href="https://bit.ly/2AlU6Ch">
              <Img fluid={data.myneosurf.childImageSharp.fluid} alt="MyNeoSurf Logo" />
            </a>
          </div>
          <div>
            <div className="contentBox textAlignCenter">
              <h1 className="smaller h3 smaller">Buy Neosurf online</h1>
              <p className="textColorDark">No Neosurf In Your Area? Discover A Crypto Alternative!</p>
            </div>
          </div>
        </div>
      </ContentRow>
      <ContentRow padV>
        <p className="textColorDark">Neosurf vouchers are trusted and widely used payment methods for secure and anonymous online transactions. Unfortunately, Noesurf vouchers cannot be purchased online directly from GetNeosurf.com right now. Still, there is nothing to worry about, as there is an alternative solution for your online payment needs - Bitcoin.</p>
        <p className="textColorDark">Bitcoin is the world's leading cryptocurrency, and it offers the same benefits of security, anonymity, and global acceptance. With Bitcoin, you can easily enjoy a digital payment experience without relying on traditional payment methods. Crypto can be used for online shopping, gaming, or funding accounts on various platforms, such as LuckyStar.io. Bitcoin is truly a versatile and future-proof alternative.</p>
        <p className="textColorDark">Transitioning to Bitcoin is easier than you might think. Here is how you can get started:</p>
        <ol>
          <li>
            <p className="textColorDark">Set up a Bitcoin wallet - This is where you will store your Bitcoin. There are many options available, such as mobile apps, desktop software, and even hardware wallets for increased security.</p>
          </li>
          <li>
            <p className="textColorDark">Purchase Bitcoin - You can buy Bitcoin from trusted cryptocurrency exchanges or online platforms using preferred payment methods such as bank transfer, credit card, or other e-wallets.</p>
          </li>
          <li>
            <p className="textColorDark">Make your payment - After you have Bitcoin in your wallet, you can use it to make payments that accept cryptocurrency, like LuckyStar.io.</p>
          </li>
        </ol>
        <p className="textColorDark">One of the major advantages of Bitcoin is that you can use it almost anywhere due to its global accessibility. Unlike traditional vouchers, which may have limited availability in certain regions, Bitcoin can be purchased and used from anywhere in the world. Bitcoin transactions are also incredibly fast, and they come with lower fees compared to other payment methods, too.</p>
        <p className="textColorDark">For users who are familiar with Neosurf, Bitcoin offers a similar level of privacy and control over your spending. Just like Neosurf vouchers, Bitcoin does not require you to share any sensitive financial information when you are making transactions. This makes it an excellent option for those who make anonymity and security their biggest priority.</p>
        <p className="textColorDark">At LuckyStar.io, you can use Bitcoin to deposit funds instantly and securely. This makes it an excellent alternative for users who are looking for a reliable and flexible way to fund their accounts. With Bitcoin, you are not limited to the availability of vouchers, which makes Bitcoin a solution that is always accessible and ready to use.</p>
        <p className="textColorDark">While Neosurf vouchers remain a fantastic payment method, exploring Bitcoin as an alternative until the vouchers are available in your area might open up new possibilities for online transactions. Ready to make the switch? Visit LuckyStar.io to learn more about using Bitcoin for secure payments!</p>
      </ContentRow>
    </>
  )
};

export default Page;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    myneosurf: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "myneosurf/myneosurf-header.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 899,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
